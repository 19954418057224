.sheet-card {
  border-color: #2a9fd6;
  border-width: 3px !important;
  transition: background-color, color 0.5s;
  user-select: none;
}

.sheet-card:hover,
.sheet-card:active {
  background-color: #2a9fd6;
  color: #060606;
}

.sheet-card.selected {
  color: #060606;
  background-color: #2a9fd6;
}

.sheet-card.active {
  animation-name: activeAnimationSheet;
  animation-timing-function: linear;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}

@keyframes activeAnimationSheet {
  from {
    background-color: #060606;
    color: #adafae;
  }

  50% {
    color: #060606;
    background-color: #2a9fd6;
  }

  to {
    color: #adafae;
    background-color: #060606;
  }
}
