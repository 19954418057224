@media (min-width: 1200px) {
  html {
    font-size: 16px;
  }
}

@media (max-width: 1199px) {
  html {
    font-size: 15px;
  }
}

@media (max-width: 599px) {
  html {
    font-size: 13px;
  }
}
